
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.projectsLinks {
	animation: fadeIn ease 3s;
	-webkit-animation: fadeIn ease 3s;
	-moz-animation: fadeIn ease 3s;
	-o-animation: fadeIn ease 3s;
	-ms-animation: fadeIn ease 3s;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 100%;
	padding: 0;
	align-items: flex-start;
	justify-content: center;
  font-family: 'Oxygen', sans-serif;
}
.projectLink {
	width: 100%;
  max-width: 27em;
	height: 40em;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background: rgba(10, 10, 10, 1);
	margin: 1em;
	border-radius: 5px;
	padding: 2em;
	box-sizing: border-box;
  filter: drop-shadow(0 0 5px grey);
}



.projectHeader {
	margin: .5em auto;
}
.gitRepoLinks {
	font-size: .75em;
}
.projectInfo {
  color: white;
  font-family: sans-serif;
  line-height: 1.5;
  font-family: 'Ubuntu', sans-serif;
}
.projectTitles {
  color: rgb(29, 158, 170);
  text-decoration: none;
  padding: 1em;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.projectTitles:hover {
	color: lightgrey;
	transition-duration: .3s;
}
.doubleRepoDiv {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: space-around;
}

.gitRepoLinks {
  color: lightgrey;
  text-decoration: none;
  font-size: 1em;
  margin: 0 .5em;
  padding: .5em;
  border: 2px solid black;
  border-radius: 8px;
}
.gitRepoLinks:hover {
  border: 2px solid white;
  color: white;
}
.titleAndPicDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

}
.appPic {
  margin-top: 1em;
  max-width: 15em;
	width: 100%;
  filter: drop-shadow(0 0 5px grey);
  border-radius: 5px;
}
.infoRepoDiv {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
