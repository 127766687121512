
.landingPage {

width: calc(100vw);
margin: -1em -.5em;
}

.homeDiv {
  box-sizing: border-box;
  height: calc(100vh );
  width: 100%;
  background-color: black;

  padding: 2em;
}

.div1 {
   background: url('../components/assets/coverimage.jpg');
   background-size: cover;
   background-position: left;
   display: flex;
   padding: 6em;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   font-family: 'Oxygen', sans-serif;
}
.div1Text {
  color: whitesmoke;
  background-color: rgba(10, 10, 10, .8);
  padding: 3em;
  margin: 0 0 7em 0;
  border-radius: 10px;

}

@keyframes bounce {
  0%,  50%,  100% {transform: translateY(0);}
  25% {transform: translateY(15px);}
  75% {transform: translateY(15px);}
}
.downArrow {
  width: 3em;
  animation: bounce 2s infinite;
}



.div2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Oxygen', sans-serif;
  color: grey;
  background-color: whitesmoke;
}
.div2Text {
  padding: 0 2em;
}
.div2Logos {
  width: 95%;
  max-width: 100em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 1em;
}
.logoRow {
  /* width: 70%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(33vh - 10em);
  margin: 2em 0;
}
.techLogo {
  height: 100%;
  max-height: 12em;
  width: auto;
  margin: 1em;
  border-radius: 5px;
  filter: drop-shadow(0 0 3px rgb(25, 25, 25));
}
.div2SecondText {

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  text-align: right;
  margin-top: 1em;
}
.downArrow2 {
  width: 3.5em;
  height: 2.5em;
  animation: bounce 2s infinite;
  color: black;
  margin: 1em 2em;
}

.div3 {
     background-color: lightgrey;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     background-image: URL('../components/assets/challengepic.jpeg');
     background-size: cover;
     padding: 0;
}
/* .challengeImg {
  width: 60%;
  height: 70%;
  border-radius: 20%;
} */
.leftDiv3 {
  box-sizing: border-box;
 height: 100%;
 width: 100%;
 background-color: rgba(100, 100, 100, .5);
 color: white;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 font-family: 'Oxygen', sans-serif;
 font-size: 2.5em;
 padding: 2em;
}

.div4 {
  background-color: black;
  color: rgb(29, 158, 170);
   font-family: 'Oxygen', sans-serif;
   display: flex;
   flex-direction: column;
   justify-content: space-around;

}
.div4 h3 {
  text-align: center;
  font-size: 2em;
  padding: 2em;

}
.landingPageContactDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 50%;

}
.landingPageContactDiv h2 {
  color: lightgrey;
}
.landingPageIconDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  height: 50%;
  width: 40%;

}
.landingPageContactIcons {
 height: 3em;
 width: auto;
 border-radius: 5px;
 margin: 5%;
 filter: drop-shadow(0 0 12px rgb(60, 60, 60));
}
.landingPageContactLink {
  margin: 1em;
}



@media (max-width: 700px) {

  .div2 h1 {
    font-size: 1em;
  }
  .downArrow2 {
    width: 2em;
    height: 1em;
  }
  .leftDiv3 {
    font-size: 1.5em;
  }
  .landingPageIconDiv {
    width: 90%;

  }
}

@media (max-width: 475px) {
  .homeDiv {
    padding: 1.5em;
  }
  .div1Text {
    padding: 1em;
  }
  .div4 h3 {
    font-size: 1.5em;
    padding: .5em;
  }
  .div2Logos {

    padding: 0;
  }
  .landingPageContactIcons {
    height: 3em;
  }
}
@media(max-width: 354px){
  .div2Logos {
    height: 75%;
    padding: 0;
  }
  .div2 {
    justify-content: space-between;
  }
  .techLogo {
    margin: .5em;
  }

}
