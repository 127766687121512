.topNav{
  display: flex;
  justify-content: space-between;
  background-color: black;
  filter: drop-shadow(0 0 8px black);
  position: fixed;
  width: calc(100vw + .5em);
  top: -.5em;
  left: -.5em;
  z-index: 2;

}
.headerLogo {
  width: 10em;
  margin-left: 2em;
}
.headerNameBadge {
  font-family: 'Oxygen', sans-serif;
  color: whitesmoke;
}
.homeLogoBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: white;
}
.headerLinks {
  text-decoration: none;
  color: grey;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.25em;
  padding: .5em;
}
.headerLinks:hover {
  text-decoration: underline;

}
.topNavLinks {
  width: 80%;
  max-width: 40em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.active {
  text-decoration: underline;
  color: whitesmoke;
}
.topPadding {
  height: 10em;
  width: 100%;
}

@media (max-width: 880px) {
  .topPadding {
    height: 8em;
  }
  .topNav {
    padding: .5em;
  }
  .headerLogo {
    width: 8em;
  }
  .headerNameBadge {
    font-size: 1.5em;
  }
  .headerLinks {
    padding: .5em;
  }
}

@media (max-width: 492px) {
  .headerLogo {
    margin-left: .5em;
    width: 7em;
  }
  .headerLinks {
    text-align: center;

    width: 40%;
    min-width: 5em;
    padding: .15em;
  }
}
