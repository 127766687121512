
@keyframes bounceIn {
0% {transform: translateY(-1000px)}
100% {transform: translateY(0)}
}

@-moz-keyframes bounceIn {
0% {transform: translateY(-1000px)}
100% {transform: translateY(0)}
}

@-webkit-keyframes bounceIn {
0% {transform: translateY(-1000px)}
100% {transform: translateY(0)}
}

@-o-keyframes bounceIn {
0% {transform: translateY(-1000px)}
100% {transform: translateY(0)}
}

@-ms-keyframes bounceIn {
0% {transform: translateY(-1000px)}
100% {transform: translateY(0)}
}




.fullAboutPage{
 margin: 2em 0;
}
.aboutContent {
  animation: bounceIn .7s;
  -webkit-animation: bounceIn .7s;
  -moz-animation: bounceIn .7s;
  -o-animation: bounceIn .7s;
  -ms-animation: bounceIn .7s;
  padding: 1em;
  border-radius: 5px;
  filter: drop-shadow(0 0 5px grey);
  background-color: black;
  max-width:50em;
  min-width: 65vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center

}
.aboutPic {
  max-width: 15rem;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 5px 5px white;
  filter: drop-shadow(0 0 5px white);
}
.aboutContent h1 {
  margin: 4em 1em 4em 1em;
  font-size: 1.25em;
  color: rgb(29, 158, 170);
  max-width: 40em;
  font-family: 'Oxygen', sans-serif;
  font-weight:  100;
}
.nameTitle {
  font-size: 2em;
  margin: 2em 0;
  color: whitesmoke;
  font-family: 'Ubuntu', sans-serif;
}
